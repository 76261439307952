export default {
  methods: {
    navigateToLearningSpace(org) {
      this.$router.push({
        name: "LearningSpace",
        params: { org: org }
      });
    },
    navigateToPartnerSpace(org) {
      this.$router.push({
        name: "PartnersDetail",
        params: { org: org }
      });
    }
  }
};
